import type { AccountId } from "query/graphql"
import { EXTERNAL_ENDPOINTS } from "common/constants/endpoints"

export const ROUTES = {
  home: (): string => "/",
  media: (): string => "/media",
  explore: (): string => "/explore",
  solutions: (): string => "/tally-product-features",
  brandKit: (): string => "/brandkit",
  profile: (voterAddress?: string): string => {
    if (!voterAddress) {
      return `/profile`
    }

    return `/profile/${voterAddress.toLowerCase()}`
  },
  user: {
    settings: (): string => "/user/settings",
    yourDAOs: (): string => "/user/your-daos",
    notifications: (): string => "/user/notifications",
    connect: (redirectUrl?: string): string => {
      if (redirectUrl) {
        return `/user/connect?redirect=${encodeURIComponent(redirectUrl)}`
      }

      return "/user/connect"
    },
    connectWallet: (redirectUrl?: string): string => {
      if (redirectUrl) {
        return `/user/connect?wallet&redirect=${encodeURIComponent(
          redirectUrl,
        )}`
      }

      return "/user/connect?wallet"
    },
    welcome: (redirect?: string): string => {
      if (redirect) {
        return `/user/welcome?redirect=${encodeURIComponent(redirect)}`
      }

      return "/user/welcome"
    },
  },
  governance: {
    id: (governanceId: string): string => `/gov/${governanceId}`,
    voters: (governanceId: string): string => `/gov/${governanceId}/voters`,
    faq: (governanceId: string): string => `/gov/${governanceId}/faq`,
    stake: {
      index: (governanceId: string): string => `/gov/${governanceId}/stake`,
      deposit: (
        governanceId: string,
        options?: {
          stepNumber: number
          depositId: number
        },
      ): string => {
        if (options)
          return `/gov/${governanceId}/stake/deposit?step=${
            options.stepNumber - 1
          }&depId=${options.depositId}`

        return `/gov/${governanceId}/stake/deposit`
      },
    },
    claim: {
      home: (governanceId: string, action?: string): string => {
        if (action) {
          return `/gov/${governanceId}/claim?action=${action}`
        }

        return `/gov/${governanceId}/claim`
      },
      start: (governanceId: string): string =>
        `/gov/${governanceId}/claim/start`,
    },
    token: {
      wrap: (orgSlug: string): string => `/gov/${orgSlug}/token/wrap`,
      unwrap: (orgSlug: string): string => `/gov/${orgSlug}/token/unwrap`,
    },
    welcome: (governanceId: string): string => `/gov/${governanceId}/welcome`,
    forum: (governanceId: string): string => `/gov/${governanceId}/forum`,
    council: {
      index: (governanceId: string, councilSlug: string): string =>
        `/gov/${governanceId}/council/${councilSlug}`,
      election: {
        index: (
          governanceId: string,
          councilSlug: string,
          electionNumber: string | number,
          action?: string,
        ): string => {
          if (action) {
            return `/gov/${governanceId}/council/${councilSlug}/election/${electionNumber}?action=${action}`
          }

          return `/gov/${governanceId}/council/${councilSlug}/election/${electionNumber}`
        },
        register: (
          governanceId: string,
          councilSlug: string,
          electionNumber: string | number,
        ): string =>
          `/gov/${governanceId}/council/${councilSlug}/election/${electionNumber}/register`,
        edit: (
          governanceId: string,
          councilSlug: string,
          electionNumber: string | number,
        ): string =>
          `/gov/${governanceId}/council/${councilSlug}/election/${electionNumber}/register/edit`,
        round1: {
          index: (
            governanceId: string,
            councilSlug: string,
            electionNumber: string | number,
            candidateFilter?: string,
          ): string => {
            if (candidateFilter) {
              return `/gov/${governanceId}/council/${councilSlug}/election/${electionNumber}/round-1?candidateFilter=${candidateFilter}`
            }

            return `/gov/${governanceId}/council/${councilSlug}/election/${electionNumber}/round-1`
          },
          candidate: (
            governanceId: string,
            councilSlug: string,
            electionNumber: string | number,
            candidateAddress: string,
          ): string =>
            `/gov/${governanceId}/council/${councilSlug}/election/${electionNumber}/round-1/candidate/${candidateAddress}`,
        },
        round2: {
          index: (
            governanceId: string,
            councilSlug: string,
            electionNumber: string | number,
          ): string =>
            `/gov/${governanceId}/council/${councilSlug}/election/${electionNumber}/round-2`,
          nominee: (
            governanceId: string,
            councilSlug: string,
            electionNumber: string | number,
            candidateAddress: string,
          ): string =>
            `/gov/${governanceId}/council/${councilSlug}/election/${electionNumber}/round-2/nominee/${candidateAddress}`,
        },
      },
    },
    draft: {
      index: (governanceId: string, draftId: string): string =>
        `/gov/${governanceId}/draft/${draftId}`,
      edit: (governanceId: string, draftId: string): string =>
        `/gov/${governanceId}/draft/${draftId}/edit`,
      history: (
        governanceId: string,
        draftId: string,
        version?: string,
      ): string => {
        if (!version) {
          return `/gov/${governanceId}/draft/${draftId}/history/latest`
        }

        return `/gov/${governanceId}/draft/${draftId}/history/${version}`
      },
    },
    proposal: {
      create: (
        governanceId: string,
        targetGovernanceId?: AccountId,
      ): string => {
        if (!targetGovernanceId) {
          return `/gov/${governanceId}/proposal/create`
        }

        return `/gov/${governanceId}/proposal/create/${targetGovernanceId}`
      },
      id: (
        governanceId: string,
        proposalId: string,
        governorId: string,
      ): string => {
        if (governorId)
          return `/gov/${governanceId}/proposal/${proposalId}?govId=${governorId}`

        return `/gov/${governanceId}/proposal/${proposalId}`
      },
      votes: (
        governanceId: string,
        proposalId: string,
        governorId: string,
      ): string => {
        if (governorId)
          return `/gov/${governanceId}/proposal/${proposalId}/votes?govId=${governorId}`

        return `/gov/${governanceId}/proposal/${proposalId}/votes`
      },
      createV2: (governorSlug: string) =>
        `/gov/${governorSlug}/proposal/create_v2`,
    },
    drafts: {
      list: (governanceId: string): string => `/gov/${governanceId}/drafts`,
    },
    proposals: {
      list: (governanceId: string): string => `/gov/${governanceId}/proposals`,
    },
    treasury: {
      list: (governanceId: string): string => `/gov/${governanceId}/treasury`,
    },
    delegates: (governanceId: AccountId, sort?: string): string => {
      if (sort) {
        return `/gov/${governanceId}/delegates?sort=${sort.toLowerCase()}`
      }

      return `/gov/${governanceId}/delegates`
    },
    delegate: {
      index: (governanceId: AccountId, redirectUrl?: string): string => {
        if (redirectUrl) {
          return `/gov/${governanceId}/delegate?redirect=${encodeURIComponent(
            redirectUrl,
          )}`
        }

        return `/gov/${governanceId}/delegate`
      },
      profile: (
        governanceId: AccountId,
        voterAddress: string,
        action?: string,
      ): string => {
        if (action) {
          return `/gov/${governanceId}/delegate/${voterAddress.toLowerCase()}?action=${action}`
        }

        return `/gov/${governanceId}/delegate/${voterAddress.toLowerCase()}`
      },
      edit: (governanceId: AccountId, redirectUrl?: string): string => {
        if (redirectUrl) {
          return `/gov/${governanceId}/delegate/edit?redirect=${encodeURIComponent(
            redirectUrl,
          )}`
        }

        return `/gov/${governanceId}/delegate/edit`
      },
      register: (governanceId: AccountId, redirectUrl?: string): string => {
        if (redirectUrl) {
          return `/gov/${governanceId}/delegate/register?redirect=${encodeURIComponent(
            redirectUrl,
          )}`
        }

        return `/gov/${governanceId}/delegate/register`
      },
      select: (
        organizationSlug: string,
        stepNumber?: string,
        address?: string,
      ): string => {
        if (stepNumber && !address) {
          return `/gov/${organizationSlug}/delegate/select?step=${stepNumber}`
        }

        if (stepNumber && address) {
          return `/gov/${organizationSlug}/delegate/select?step=${stepNumber}&address=${address}`
        }

        return `/gov/${organizationSlug}/delegate/select`
      },
    },
    guardians: (governanceId: AccountId): string =>
      `/gov/${governanceId}/guardians`,
    securityCouncilMembers: (governanceId: AccountId): string =>
      `/gov/${governanceId}/security-council-members`,
    myVotingPower: {
      index: (governanceId: AccountId): string =>
        `/gov/${governanceId}/my-voting-power`,
      received: (governanceId: AccountId): string =>
        `/gov/${governanceId}/my-voting-power/received`,
    },
    settings: {
      index: (governanceId: string): string => `/gov/${governanceId}/settings`,
      basics: (governanceId: string): string =>
        `/gov/${governanceId}/settings/basics`,
      admins: (governanceId: string): string =>
        `/gov/${governanceId}/settings/admins`,
      safes: (governanceId: string): string =>
        `/gov/${governanceId}/settings/safes`,
      voting: (governanceId: string): string =>
        `/gov/${governanceId}/settings/voting`,
      safe: {
        link: (governanceId: string): string =>
          `/gov/${governanceId}/settings/safes/link`,
        create: (governanceId: string): string =>
          `/gov/${governanceId}/settings/safes/create`,
      },
    },
  },
  register: {
    protocol: (): string => `/register/protocol`,
    governance: (): string => `/register/governance`,
    organization: (): string => `/register/organization`,
    token: (): string => `/register/token`,
  },
  getStarted: {
    index: (): string => "/get-started",
    governor: (): string => "/add-a-dao/governor",
  },
  organization: {
    id: (organizationId: string): string => `/org/${organizationId}`,
    edit: (organizationId: string): string => `/org/${organizationId}/edit`,
    addGovernor: (organizationId: string): string =>
      `/org/${organizationId}/add-governor`,
    createSafe: (organizationId: string): string =>
      `/org/${organizationId}/create-safe`,
    addSafe: (organizationId: string): string =>
      `/org/${organizationId}/add-safe`,
    createSafeTransaction: (organizationId: string): string =>
      `/org/${organizationId}/create-safe-transaction`,
  },
  safe: {
    id: (safeId: string, organizationSlug?: string): string => {
      if (!organizationSlug) return `/safe/${safeId}`

      return `/safe/${safeId}?org=${organizationSlug}`
    },
    edit: (safeId: string): string => `/safe/${safeId}/edit`,
  },
  dao: {
    index: () => {
      return `/dao`
    },
    delegate: (voterAddress: string) => {
      return `/dao/delegate/${voterAddress}`
    },
  },
}

export const API_ROUTES = {
  searchDaos: () => "/api/search-daos",
  safes: (organizationId: string, timestamp?: number) => {
    if (timestamp) {
      return `/api/safes?organizationId=${organizationId}&_t=${timestamp}`
    }

    return `/api/safes?organizationId=${organizationId}`
  },
  governance: (organizationId: string): string => {
    const searchParams = new URLSearchParams({
      organizationId: organizationId,
    })

    return `/api/governance?${searchParams.toString()}`
  },
  governanceHeader: (governanceId: string): string => {
    return `/api/governance-header?governanceId=${governanceId}`
  },
  governanceModuleInformation: (governanceId: AccountId): string => {
    return `/api/governance-module-information?governanceId=${governanceId}`
  },
  txRelayer: {
    delegations: {
      eligible: (
        organizationId: string,
        tokenId: string,
        accountAddress: string,
      ): string => {
        return `/api/tx-relay/delegations/eligible?organizationId=${organizationId}&tokenId=${tokenId}&accountAddress=${accountAddress}`
      },
      submit: (): string => {
        return `/api/tx-relay/delegations/submit`
      },
    },
    voting: {
      eligible: (
        governanceId: string,
        accountAddress: string,
        proposalId: string,
      ): string => {
        return `/api/tx-relay/voting/eligible?governanceId=${governanceId}&accountAddress=${accountAddress}&proposalId=${proposalId}`
      },
      submit: (): string => {
        return `/api/tx-relay/voting/submit`
      },
    },
  },
  claim: {
    eligibilityConditions: (governanceSlug: string, accountAddress: string) =>
      `/api/claim/eligibility-conditions?accountAddress=${accountAddress}&governanceSlug=${governanceSlug}&v=2`,
    airdropEligible: (governanceSlug: string, accountAddress: string) =>
      `/api/claim/airdrop-elegible?accountAddress=${accountAddress}&governanceSlug=${governanceSlug}`,
  },
  auto: {
    textCompletion: (): string => {
      return `/api/auto/text-completion`
    },
  },
}

export const EXTERNAL_ROUTES = {
  marketing: {
    pricing: {
      link: {
        free: (): string => "",
        premium: (): string => "https://42r49n08les.typeform.com/to/HtauYVbJ",
        enterprise: (): string =>
          "https://42r49n08les.typeform.com/to/HpE6JDKR",
      },
    },
  },
  tally: {
    medium: (): string => "https://blog.tally.xyz",
    profile: (addressOrEns: string): string =>
      `${process.env.NEXT_PUBLIC_TALLY_SIWE_URI}/profile/${addressOrEns}`,
    wiki: (): string => "https://wiki.tally.xyz/docs",
    github: (): string => "https://github.com/withtally/",
    daoTalk: (): string =>
      "https://open.spotify.com/show/6nCG7KVFfn28NHgLmQH5tv?si=0d903cfe0df44bfa&nd=1",
    youtube: (): string =>
      "https://www.youtube.com/channel/UC0J5KuukgFn8KCMuuhkBZQw",
    youtubeShorts: (): string => "https://www.youtube.com/@tallyxyz/shorts",
    careers: (): string => "https://careers.tally.xyz",
    discord: (): string => "https://discord.gg/tallyxyz",
    instagram: (): string => "https://instagram.com/tally.xyz",
    support: (): string => "https://support.tally.xyz",
    docs: {
      index: (): string => "https://docs.tally.xyz",
      apiWelcome: (): string => "https://docs.tally.xyz/tally-api/welcome",
      arbSecurityCouncil: (): string =>
        "https://www.tally.xyz/gov/arbitrum/council/security-council",
      councilElections: (): string =>
        "https://docs.tally.xyz/premium-features/security-council-elections",
      connectWithASafe: (): string =>
        "https://docs.tally.xyz/knowledge-base/navigating-the-tally-platform/getting-started#connect-with-a-safe",
      signInAsSafe: (): string =>
        "https://docs.tally.xyz/knowledge-base/navigating-the-tally-platform/getting-started#sign-in-as-safe",
      deployingGovernorDaos: (): string =>
        "https://docs.tally.xyz/user-guides/deploying-governor-daos",
      deployGovernor: (): string =>
        "https://docs.tally.xyz/user-guides/deploying-governor-daos/deploy-a-governor",
      deployGovernanceToken: (): string =>
        "https://docs.tally.xyz/user-guides/deploying-governor-daos/deploy-a-governance-token",
      daoLauncher: (): string =>
        "https://docs.tally.xyz/premium-features/dao-launcher",
      knowledgeBase: (): string =>
        "https://docs.tally.xyz/knowledge-base/tally",
      delegationsOnTally: (): string =>
        "https://docs.tally.xyz/knowledge-base/delegations-on-tally",
      delegatingVotingPower: (): string =>
        "https://docs.tally.xyz/knowledge-base/proposals/delegating-voting-power",
      howToDelegate: (): string =>
        "https://docs.tally.xyz/knowledge-base/proposals/delegate-your-voting-power",
      freeDelegation: (): string =>
        "https://docs.tally.xyz/premium-features/relay/free-delegation",
      freeVoting: (): string =>
        "https://docs.tally.xyz/premium-features/relay/gasless-voting",
      creatingProposals: (): string =>
        "https://docs.tally.xyz/knowledge-base/proposals/creating-proposals",
      queuingProposals: (): string =>
        "https://docs.tally.xyz/knowledge-base/proposals/managing-proposals#queuing-and-executing-proposals",
      executingProposals: (): string =>
        "https://docs.tally.xyz/knowledge-base/proposals/managing-proposals#queuing-and-executing-proposals",
      cancelingProposals: (): string =>
        "https://docs.tally.xyz/knowledge-base/proposals/managing-proposals#canceling-proposals",
      managingADao: (): string =>
        "https://docs.tally.xyz/knowledge-base/managing-a-dao",
      runningProposalSimulations: (): string =>
        "https://docs.tally.xyz/knowledge-base/proposals/creating-proposals#running-proposal-simulations",
      linkingGnosisSafe: (): string =>
        "https://docs.tally.xyz/knowledge-base/managing-a-dao/gnosis-safe",
      governorFramework: (): string =>
        "https://docs.tally.xyz/knowledge-base/tally/governor-framework",
      openZeppelinGovernor: (): string =>
        "https://docs.tally.xyz/user-guides/tally-contract-compatibility/openzeppelin-governor",
      onChainVsOffChain: (): string =>
        "https://docs.tally.xyz/knowledge-base/tally/on-chain-vs-off-chain",
      statusPage: (): string => "https://status.tally.xyz",
      swaps: (): string =>
        "https://docs.tally.xyz/knowledge-base/proposals/swaps",
      networkSupport: (): string =>
        "https://docs.tally.xyz/user-guides/tally-contract-compatibility/network-support",
      governorParameters: (): string =>
        "https://docs.tally.xyz/user-guides/deploying-governor-daos/choose-governor-parameters",
      voteDelegation: (): string =>
        "https://docs.tally.xyz/education/governance-concepts/vote-delegation",
      tallyFees: (): string =>
        "https://docs.tally.xyz/knowledge-base/tally-fees",
      deploymentGuide: (): string =>
        "https://883959685-files.gitbook.io/~/files/v0/b/gitbook-x-prod.appspot.com/o/spaces%2F-MQO0N_aitpkSUyz4BYE%2Fuploads%2FdFreeRqhTgp5BYVegtxv%2FUsing%20Governor.pdf?alt=media&token=2327591d-6154-48b0-a805-a1be7ba271a1",
    },
    terms: (): string => "https://terms.tally.xyz/",
    releases: (): string => "https://feedback.tally.xyz/changelog",
    mirror: (): string => "https://tally.mirror.xyz/",
    resources: {
      deployNftStyleDao: (): string =>
        "https://www.youtube.com/watch?v=cAbHwCWJAG4&t=1389s&ab_channel=Tally",
    },
    form: {
      requestAdminRole: (): string =>
        "https://notionforms.io/forms/tally-admin-update",
    },
  },
  contentGuild: (): string => "https://contentguild.xyz/",
  daoNYC: (): string => "https://www.dao-nyc.xyz",
  twitter: {
    tally: (): string => "https://twitter.com/tallyxyz",
    tweet: (tweet: string): string =>
      `https://twitter.com/intent/tweet?text=${tweet}`,
    tweetWithUrl: (tweet: string, url: string): string =>
      `https://twitter.com/intent/tweet?text=${tweet}&url=${encodeURIComponent(
        url,
      )}`,
    releaseTweet: (tweet: string, url: string): string =>
      `https://twitter.com/intent/tweet?text=${tweet}&url=${encodeURIComponent(
        url,
      )}&hashtags=tally,daos,release`,
    handle: (twitterHandle: string): string =>
      `https://twitter.com/${twitterHandle}`,
  },
  typeform: (): string => "https://hbqz4d3c3ab.typeform.com/to/bAarIhYJ",
  newsletter: {
    preview: (): string => "https://newsletter.tally.xyz/?no_cover=true",
    home: (): string => "https://newsletter.tally.xyz",
  },
  etherscan: {
    hash: (chainReference: number, txHash: string): string =>
      `${EXTERNAL_ENDPOINTS.etherscan(chainReference)}/tx/${txHash}`,
    address: (chainReference: number, address: string): string =>
      `${EXTERNAL_ENDPOINTS.etherscan(chainReference)}/address/${address}`,
    token: (chainReference: number, address: string): string =>
      `${EXTERNAL_ENDPOINTS.etherscan(chainReference)}/token/${address}`,
    block: (chainReference: number, blockNumber: number): string =>
      `${EXTERNAL_ENDPOINTS.etherscan(chainReference)}/block/${blockNumber}`,
  },
  safe: {
    home: (networkShortname: string, safeAddress: string): string =>
      `https://app.safe.global/home?safe=${networkShortname}:${safeAddress}`,
    txsQueue: (networkShortname: string, safeAddress: string): string =>
      `https://app.safe.global/transactions/queue?safe=${networkShortname}:${safeAddress}`,
    txsHistory: (networkShortname: string, safeAddress: string): string =>
      `https://app.safe.global/transactions/history?safe=${networkShortname}:${safeAddress}`,
    queue: (safeAddress: string): string =>
      `https://app.safe.global/transactions/queue?safe=${safeAddress}`,
  },
  privacyPolicy: (): string =>
    "https://www.iubenda.com/privacy-policy/20084196",
  delegationWeek: (): string => "https://delegationweek.com/",
  mobileDeepLink: {
    metamask: (url: string): string => {
      if (url) {
        return `https://metamask.app.link/dapp/${url}`
      }

      return `https://metamask.app.link/dapp`
    },
    coinbaseWallet: (url: string): string => {
      if (url) {
        return `https://go.cb-w.com/dapp?cb_url=${url}`
      }

      return `https://go.cb-w.com/dapp`
    },
  },
  openZeppelin: {
    deployAGovernor: (): string =>
      "https://docs.openzeppelin.com/contracts/4.x/governance",
    governorDocs: (): string =>
      "https://docs.openzeppelin.com/contracts/5.x/governance",
  },
  notifications: {
    index: (accountId?: string): string => {
      if (accountId) {
        return `https://notify.tally.xyz/?u=${accountId}`
      }

      return "https://notify.tally.xyz"
    },
    dao: (orgId: string): string => {
      return `https://notify.tally.xyz/home?gov=${orgId}`
    },
  },
  discourse: {
    topic: (discourseBaseUrl: string, postSlug: string): string => {
      return `${discourseBaseUrl}/t/${postSlug}`
    },
  },
  unistaker: {
    docs: (): string => "https://docs.unistaker.io/",
  },
}
