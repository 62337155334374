import type { ReactElement } from "react"
import React, {
  createContext,
  useState,
  useRef,
  useContext,
  useEffect,
} from "react"
import * as Sentry from "@sentry/nextjs"
import type { Signer } from "@ethersproject/abstract-signer"

import { getErrorMessage } from "governance/helpers/error"
import { useTransactionToast } from "web3/providers/TransactionToastProvider"
import type {
  TransactionFunction,
  TransactionOn,
  TransactionStateBegin,
  TransactionStateFail,
  TransactionStateMining,
  TransactionStateNone,
  TransactionStateSuccess,
  TransactionStatus,
  TransactionToastMessages,
} from "web3/types/transaction"
import { TransactionState } from "web3/types/transaction"
import type { TransactionError } from "common/components/ErrorBoundary"
import {
  ErrorType,
  Severity,
  toFormattedString,
} from "common/components/ErrorBoundary"
import { getMulticall } from "web3/hooks/useMulticall"
import type { CallStruct } from "contracts/bindings/Multicall2"
import { useMe } from "user/providers/MeProvider"
import { useConnector } from "web3/hooks/useConnector"

type Value<Metadata> = {
  send: (transactionFunction: TransactionFunction) => void
  multisend: (signer: Signer, calls: CallStruct[]) => void
  setOn: (on: TransactionOn<Metadata>) => void
  status: TransactionStatus<Metadata>
  setMetadata: (metadata: Metadata) => void
}

// @ts-expect-error TODO: think how to instance the "Context" to be shared on "Provider" and consumer hook,
//                        which types aren't complaining. I'm positive that the types work anyway
const TransactionContext = createContext<Value<Metadata>>(
  // @ts-expect-error It's a good practice not to give a default value even though the linter tells you so
  {},
)

const INITIAL_STATUS: TransactionStateNone = {
  state: TransactionState.None,
}

export function TransactionProvider<Metadata>({
  children,
}: {
  children: ReactElement
}): JSX.Element {
  const me = useMe()
  const [on, setOn] = useState<TransactionOn<Metadata>>()
  const [status, setStatus] =
    useState<TransactionStatus<Metadata>>(INITIAL_STATUS)

  const metadata = useRef<Metadata>({} as Metadata)
  const { isWalletConnect } = useConnector()

  const setMetadata = (nextMetadata: Metadata): void => {
    metadata.current = nextMetadata
  }

  const send = async (transactionFunction: TransactionFunction) => {
    try {
      const beginStatus: TransactionStateBegin = {
        state: TransactionState.Begin,
      }

      setStatus(beginStatus)

      if (isWalletConnect) {
        // TODO(@nicolas): I can't make useToast to work here
        alert(
          "The transaction will be sent to the connected wallet via WalletConnect",
        )
      }

      const transaction = await transactionFunction()

      const miningStatus: TransactionStateMining<Metadata> = {
        state: TransactionState.Mining,
        transaction,
        metadata: metadata.current,
      }

      setStatus(miningStatus)

      on?.Mining?.(miningStatus)

      const receipt = await transaction.wait()

      const successStatus: TransactionStateSuccess<Metadata> = {
        state: TransactionState.Success,
        receipt,
        transaction,
        metadata: metadata.current,
      }

      setStatus(successStatus)

      on?.Success?.(successStatus)

      setTimeout(() => {
        setStatus(INITIAL_STATUS)
      }, 3000)
    } catch (_error) {
      const filterErrorKeys = (error: Record<string, any>) => {
        return Object.fromEntries(
          Object.entries(error).filter(([key]) => key !== "transaction"),
        )
      }

      const cleanedError = filterErrorKeys(_error as Record<string, any>)

      const error: TransactionError = {
        type: ErrorType.Transaction,
        context: {
          error: cleanedError,
        },
      }

      Sentry.captureException(new Error(toFormattedString(error)), (scope) => {
        scope.setLevel(Severity.Warning)

        if (me) {
          scope.setContext("user", {
            user: toFormattedString(me),
          })
        }

        return scope
      })

      const errorMessage = getErrorMessage(error)
      const failStatus: TransactionStateFail<Metadata> = {
        error: errorMessage,
        state: TransactionState.Fail,
        metadata: metadata.current,
      }

      setStatus(failStatus)
      on?.Fail?.(failStatus)

      setTimeout(() => {
        setStatus(INITIAL_STATUS)
      }, 5000)
    }
  }

  const multisend = (signer: Signer, calls: CallStruct[]) => {
    const UNISWAP_MULTICALL_CONTRACT_ADDRESS =
      "0x5ba1e12693dc8f9c48aad8770482f4739beed696"
    const multicallContract = getMulticall(
      UNISWAP_MULTICALL_CONTRACT_ADDRESS,
      signer,
    )

    send(() => multicallContract.aggregate(calls))
  }

  return (
    <TransactionContext.Provider
      value={{
        status,
        send,
        multisend,
        setMetadata,
        setOn,
      }}
    >
      {children}
    </TransactionContext.Provider>
  )
}

export function useTransaction<Metadata>({
  on,
  messages,
}: {
  on?: TransactionOn<Metadata>
  messages?: Partial<TransactionToastMessages>
} = {}): {
  send: (transactionFunction: TransactionFunction) => void
  status: TransactionStatus<Metadata>
  multisend: (signer: Signer, calls: CallStruct[]) => void
  setMetadata: (metadata: Metadata) => void
} {
  const { send, setMetadata, setOn, status, multisend } =
    useContext<Value<Metadata>>(TransactionContext)
  const mounted = useRef<boolean>(false)

  useEffect(() => {
    if (!mounted.current && on) {
      setOn(on)

      mounted.current = true
    }
  }, [mounted, on, setOn])

  useTransactionToast({ messages })

  return {
    send,
    status,
    multisend,
    setMetadata,
  }
}
